// Don't edit this data
export const bscTestNetContract = "0xBC227b7E290549c77Cf5e89B983511FFF6338A2c";
export const bscContract = "0x737a3d051Ba5121846D514D477cf4937bDdd5555"; // compiler 0.8.15
export const ethContract = "0x3948b40ee53891A94A8822a3621A12F415eF6B68";
export const arbitrumContract = "";
export const polygonContract = "0xBC227b7E290549c77Cf5e89B983511FFF6338A2c"; // compiler 0.8.15
export const avalancheContract = "0xBC227b7E290549c77Cf5e89B983511FFF6338A2c"; // compiler 0.8.15
export const fantomContract = "0xBC227b7E290549c77Cf5e89B983511FFF6338A2c"; // compiler 0.8.15
export const goerliContract = "0xBC227b7E290549c77Cf5e89B983511FFF6338A2c";
export const baseContract = "0x74775eAb03E010a5024408e6a4989aDd56e01183"; // compiler 0.8.15

export const abi = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "aff",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "cOwner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "marketingWallet",
				"type": "address"
			},
			{
				"internalType": "string",
				"name": "name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "symbol",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "totalSupply",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "taxFee",
				"type": "uint256"
			}
		],
		"name": "createToken",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_theOwner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "fee",
				"type": "uint256"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "beneficiary",
				"type": "address"
			}
		],
		"name": "withdrawEther",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "theOwner",
		"outputs": [
			{
				"internalType": "address payable",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];