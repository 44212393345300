
import React from "react";
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from "reactstrap";
import Footer from "components/Footers/Footer.js";
import {bscContract, ethContract, arbitrumContract, polygonContract, avalancheContract, fantomContract, bscTestNetContract, goerliContract, baseContract, abi} from 'components/config';



function Index() {

  const [currentAccount, setCurrentAccount] = useState(null);
  const [OwnerWalletText, setOwnerWalletText] = useState(null);
  const [MarketingWalletText, setMarketingWalletText] = useState(null);
  const [TaxText, setTaxText] = useState(null);
  const [SupplyText, setSupplyText] = useState(null);
  const [TokenNameText, setTokenNameText] = useState(null);
  const [SymbolText, setSymbolText] = useState(null);
  const [TwitterText, setTwitterText] = useState(null);
  const [FacebookText, setFacebookText] = useState(null);
  const [TelegramText, setTelegramText] = useState(null);
  const [ChainText, setChainText] = useState("BNB Smart Chain");
  const [ChainId, setChainId] = useState("56");
  const [AffWallet, setAffWallet] = useState("0x3Fa53d28C63eBA18a6dc6e924cf1AF8DaA279DA2");
  const [Fee, setFee] = useState("30000000000000000");
  const [FactoryContract, setFactoryContract] = useState("0x737a3d051Ba5121846D514D477cf4937bDdd5555");


  const handlChainChange = (event) => {

    switch (event.target.value) {
      case 'BNB Smart Chain':
        setFactoryContract(bscContract);
        setChainId('56');
        break;
      case 'Ethereum':
        setFactoryContract(ethContract);
        setChainId('1');
        break;
      case 'Base':
          setFactoryContract(baseContract);
          setChainId('8453');
          break;
      case 'Arbitrum':
        setFactoryContract(arbitrumContract);
        setChainId('42161');
        break;
      case 'Polygon':
        setFactoryContract(polygonContract);
        setChainId('137');
        break;
      case 'Avalanche':
        setFactoryContract(avalancheContract);
        setChainId('43114');
        break;
      case 'Fantom':
        setFactoryContract(fantomContract);
        setChainId('250');
        break;
      case 'BNB Smart Chain TestNet':
        setFactoryContract(bscTestNetContract);
        setChainId('97');
        break;
      case 'Goerli TestNet':
        setFactoryContract(goerliContract);
        setChainId('5');
        break;
      default:
        setFactoryContract(bscContract);
        setChainId('56');
        break;
    }

    setChainText(event.target.value);
    fetchData();
  };

  const handleSymbolChange = (event) => {
    setSymbolText(event.target.value);
  };

  const handleTokenNameChange = (event) => {
    setTokenNameText(event.target.value);
  };

  const handleMarketingWalletChange = (event) => {
    setMarketingWalletText(event.target.value);
  };

  const handleOwnerWalletChange = (event) => {
    setOwnerWalletText(event.target.value);
  };

  const handleTaxChange = (event) => {
    setTaxText(event.target.value);
  };

  const handleSupplyChange = (event) => {
    setSupplyText(event.target.value);
  };

  const checkWalletIsConnected = () => {
    const { ethereum } = window;
    if(!ethereum){
      console.log("Metamask NOT Installed");
      return;
    }else{
      console.log("Metamask Installed");
    }

   }


  const connectWalletHandler = async() => { 
    const { ethereum } = window;
    if(!ethereum){
      alert("Please Install Metamask!");
    }

    if(window.ethereum.networkVersion !== ChainId){
        alert(`Please change the MetaMask network to ${ChainText}`);
        return;
    }

    try{
      const accounts = await ethereum.request({method: 'eth_requestAccounts'});
      console.log("Found an account :", accounts[0]);
      setCurrentAccount(accounts[0]);
    }catch (err){
      console.log(err);
    }
  }

  const connectWalletButton = () => {
    return (
      <Button
        className="mt-4"
        color="primary"
        type="button"
        onClick={connectWalletHandler}
        // onClick={toggleModal}
        >
        Wallet Connect
      </Button>
    )
  }

  const createButton = () => {
    return (
      <Button
        className="mt-4"
        color="primary"
        type="button"
        onClick={createNewContract}
        >
        Create Token
      </Button>
    )
  }

  const fetchData = async () => {
    try {
      const response = await axios.get('data.json');
      setAffWallet(response.data[0].value);

      switch (ChainId) {
        case '56':
          setFee(response.data[1].value);
          break;
        case '1':
          setFee(response.data[2].value);
          break;
        case '42161':
          setFee(response.data[3].value);
          break;
        case '137':
          setFee(response.data[4].value);
          break;
        case '43114':
          setFee(response.data[5].value);
          break;
        case '250':
          setFee(response.data[6].value);
          break;
        case '97':
          setFee(response.data[1].value);
          break;
        case '5':
          setFee(response.data[2].value);
          break;
        case '8453':
            setFee(response.data[7].value);
            break;
        default:
          setFee(response.data[1].value);
          break;
      }
      setTwitterText(response.data[8].value);
      setFacebookText(response.data[9].value);
      setTelegramText(response.data[10].value);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  useEffect(() => {
    checkWalletIsConnected(); 
    fetchData();
  });


  const createNewContract = async() => {
    if(!validateVariables(OwnerWalletText, parseInt(TaxText, 10))){
      alert("You must enter valid wallet address and the tax must be from 0 to 10");
      return;
    }
    if(window.ethereum.networkVersion !== ChainId){
      alert(`Please change the MetaMask network to ${ChainText}`);
      return;
    }
    try{
      const { ethereum } = window;

      if(ethereum){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(FactoryContract, abi, signer);

        console.log("Intialize payment");
        let newToken;
        if(ChainId === "1")
        {
          newToken = await contract.createToken(OwnerWalletText, MarketingWalletText, TokenNameText, SymbolText, SupplyText, TaxText, {value: Fee});
        }else{
          newToken = await contract.createToken(AffWallet, OwnerWalletText, MarketingWalletText, TokenNameText, SymbolText, SupplyText, TaxText, {value: Fee});
        }

        if(newToken){
          alert("Congratulations, Your token has beed created");
        }

      }
    }catch(err){
      alert("Something wrong, Some fields missing or maybe you don't have enough balance for transaction fee.");
      console.log(err);
    }

  }


  function validateVariables(ethereumAddress, tax) {
    // Check Ethereum address
    const ethereumAddressRegex = /^0x[a-fA-F0-9]{40}$/;
    const isValidEthereumAddress = ethereumAddressRegex.test(ethereumAddress);
  
    // Check tax
    const isValidNumber = Number.isInteger(tax) && tax >= 0 && tax <= 10;
  
    // Return validation result
    return isValidEthereumAddress && isValidNumber;
  }


    return (
      <>
        <main>
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <div className="text-center">
            <img
                  alt="..."
                  src="logo.png"
                  width={750}
                />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                
                <Col lg="9">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white pb-5">
                      <div className="text-muted text-center mb-3">
                        <h2>Create Token</h2>
                        <p>Create your own token with liquidity and tax fee on many networks in less than a minute with our full audited smart contract solidity code, 100% token ownership.</p>
                      </div>

                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      
                      <Form role="form">

                      <FormGroup>
                        <Input
                          id="chainSelect"
                          name="select"
                          type="select"
                          value={ChainText}
                          onChange={handlChainChange}
                        >
                          <option>
                            BNB Smart Chain
                          </option>
                          <option>
                            Base
                          </option>
                          <option>
                            Polygon
                          </option>
                          <option>
                            Avalanche
                          </option>
                          <option>
                            Fantom
                          </option>
                          <option>
                            BNB Smart Chain TestNet
                          </option>
                          <option>
                            Goerli TestNet
                          </option>
                        </Input>
                      </FormGroup>

                      <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fa fa-btc" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              placeholder="Token Name" 
                              type="text" 
                              value={TokenNameText}
                              onChange={handleTokenNameChange}
                              required 
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fa fa-empire" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              placeholder="Symbol" 
                              type="text" 
                              value={SymbolText}
                              onChange={handleSymbolChange}
                              required 
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-money-coins" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              placeholder="Total Supply" 
                              type="number" 
                              value={SupplyText}
                              onChange={handleSupplyChange}
                              required 
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fa fa-money" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              placeholder="Tax" 
                              type="number" 
                              value={TaxText}
                              onChange={handleTaxChange}
                              required 
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-folder-17" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              placeholder="Owner Wallet" 
                              type="text" 
                              value={OwnerWalletText}
                              onChange={handleOwnerWalletChange}
                              required 
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-folder-17" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              placeholder="Marketing Wallet" 
                              type="text" 
                              value={MarketingWalletText}
                              onChange={handleMarketingWalletChange}
                              required 
                            />
                          </InputGroup>
                        </FormGroup>

                        <div className="text-center">
                          {currentAccount ? createButton() : connectWalletButton()}
                        </div>
                      </Form>

                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <Footer twitter={TwitterText} facebook={FacebookText} telegram={TelegramText}/>
      </>
    )
}

export default Index;
